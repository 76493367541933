import React from 'react'

function LeftArrowIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      height="32px"
      width="32px"
      style={{ margin: '0 0.5rem', verticalAlign: 'middle' }}
      {...props}
    >
      <path d="M11.999 1.993C6.486 1.994 2 6.48 1.999 11.994c0 5.514 4.486 10 10.001 10 5.514-.001 10-4.487 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8 .001-4.411 3.59-8 8-8.001C16.411 3.994 20 7.583 20 11.994c0 4.41-3.589 7.999-8 8z" />
      <path d="M12.012 7.989l-4.005 4.005 4.005 4.004v-3.004h3.994v-2h-3.994z" />
    </svg>
  )
}

export default LeftArrowIcon
